import {format} from "date-fns";
import esLocale from "date-fns/locale/es";

export const getPlatforms = (groups) => {
    let platforms = [];

    if (groups) {
        platforms = groups.filter(group => group.platform !== null)
            .map(group => {
                return group.platform;
            });
    }

    return [...new Set(platforms)];
}

export const getProfileName = (profile) => {
    let profileName = '';

    switch (profile) {
        case 'PLATFORM_MANAGER':
            profileName = 'Administrador de Plataforma';
            break;
        case 'LIMITED_MANAGER':
            profileName = 'Administrador Limitado';
            break;
        case 'BASIC_MANAGER':
            profileName = 'Administrador Básico';
            break;
        case 'LIMITED_CONTACT':
            profileName = 'Contacto Limitado';
            break;
        default:
            profileName = 'App Mobile';
    }

    return profileName;
}


export const getStatus = (status) => {
    let name = '';

    switch (status) {
        case 'ACTIVE':
            name = 'Activo';
            break;
        case 'INACTIVE':
            name = 'Inactivo';
            break;
        default:
            name = 'Inactivo';
    }

    return name;
}

export const getIdWithoutHash = (id) => {
    if (id) {
        const identifier = atob(id);
        return identifier.split(":")[1];
    }

    return 'N/A';
};

export const getModelHashId = (id) => {
    if (id) {
        const identifier = atob(id);
        return identifier.split(":")[0];
    }

    return null;
};


export const removeOverlay = () => {
    const divOverview = document.getElementById('member-overview');
    divOverview.classList.remove('show');
    divOverview.removeAttribute('aria-modal');
    divOverview.removeAttribute('role');

    const divBackdrop = document.querySelector('.offcanvas-backdrop')
    divBackdrop.remove();
};

export const createdAtFormat = (value) => {
    if (value) {
        const date = format(new Date(value), 'EEEE dd MMMM yyyy h:mm aaa', {locale: esLocale});

        return date.charAt(0).toUpperCase() + date.slice(1);
    }

    return value;
};
